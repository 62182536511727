//styling
import '../../App.css';
import "../../css/customer_portal.css";
import "../../css/normalize.css";

//react components
import React, { useState, useEffect  } from "react";
import { useSearchParams } from "react-router-dom";

//third-party components
import LinearProgress from '@mui/material/LinearProgress';
import Grid from '@mui/material/Grid';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import SendIcon from '@mui/icons-material/Send';
import Link from '@mui/material/Link';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import OutlinedInput from '@mui/material/OutlinedInput';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Autocomplete from '@mui/material/Autocomplete';
//customcomponents
import POC from './POC';

//libraries
import { GetMyPOC } from '../../libs/POC';


export default function MyPOC(props){

    const [isLoading, setIsLoading]=useState(true);
    const [poc,setPOC]=useState(props.pocData);
    const [selected, setSelected] = useState();
    const [searchParams,setSearchParams ] = useSearchParams();
    const [contactus,setContactUs] = useState(false);
    const [personName, setPersonName] = useState([]);

    const MenuProps = {
        PaperProps: {
          style: {
            maxHeight: 48 * 4.5 + 8,
            width: 250,
          },
        },
      };


    const handleChange = (SelectChangeEvent) => {
        
        let tmp = personName;
        tmp.push(SelectChangeEvent.target.value);
        setPersonName(tmp);
    };

    useEffect(()=>{
        if(props.pocData){
            setPOC(props.pocData);
        }
    },[props.pocData])



    useEffect(() => {
        if(!poc && props.isSelected){
            GetMyPOC(searchParams.get('userId')||props.userId).then((pocdata)=>{
                setPOC(pocdata);
                setIsLoading(false);
                
                if(props.onData){
                    props.onData(pocdata);
                }
            })
            .catch((err)=>{
                console.error('error fetching poc information',err);
                setIsLoading(false);
                if(props.onData){
                    props.onData({});
                }
            });
        }else{
            setIsLoading(false);
        }
        setSelected(props.isSelected);
    
      }, [props.isSelected]);


    return(
        (selected)?
        (isLoading || !(poc))?
        <div id="my_poc" className="my_poc">
            <div className="row row_padding">
            <LinearProgress />
            </div>
        </div>
        :
            <div id="my_poc" className="my_poc">
                <div className="row row_padding">
                    <p>Your Abbott Point of Care Contacts are here to support you! <Link href="#" onClick={()=>{setContactUs(true)}} key="sendmessage">Send A Message</Link></p>
                    <Grid container rowSpacing={1} columnSpacing={{ xs:1,sm:2, md:3}}>
                    {poc.map(p=><POC key={p.poc_id} pocData={p} />)}
                    </Grid>
                </div>
                <Dialog
                    open={(contactus)}
                    PaperProps={{ style: {
                        minHeight: '500px',
                        maxHeight: '90%',
                        minWidth: '600px',
                        maxWidth: '90%',
                        width: '1024px'
                        }}}
                >
                    <DialogTitle>
                        Send a Message
                    </DialogTitle>
                    <IconButton
                    aria-label="close"
                    onClick={()=>{setContactUs(false);setPersonName([])}}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                    >
                    <CloseIcon />
                    </IconButton>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            Send a message to one or many contacts by completing the following form.
                        </DialogContentText>
                        <FormControl sx={{ m: 1, width: 800,}}>
                            <FormControl sx={{margin:1}}>
                            <Autocomplete
                                disablePortal
                                id="ddlPOC"
                                required
                                multiple
                                options={poc}
                                getOptionLabel={(option) => option.poc_fullname}
                                renderInput={(params) => <TextField {...params} label="Contact Name" />}
                                onChange={(e,v)=>{
                                                    setPersonName(v)
                                                }}
                                value={personName}
                            />
                            </FormControl>
                            <FormControl sx={{margin:1}}>
                            <TextField
                                id="poc-message"
                                label="Message"
                                multiline
                                rows={6}
                                placeholder='Type your message here.  Your name and contact details will be automatically provided.'
                                />
                            </FormControl>
                        </FormControl>
                        <div>
                        <Button 
                            variant="contained" 
                            endIcon={<SendIcon />}
                            onClick={()=>{setContactUs(false);setPersonName([])}}
                        >
                            Send Message
                        </Button>
                        </div>
                    </DialogContent>
                    <DialogActions>
                    </DialogActions>
                </Dialog>
            </div>
        :<></>
        

    )
}



