
import { Auth,API } from "aws-amplify";

const APINAME='customer'
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
const GetAllCustomers1= async(rows, pageSize=5, currentPage=0, search, filter, sortBy, order)=>{
    
    return new Promise(async (resolve,reject)=>{
        try{
            let customers = await API.get(APINAME,'/');
            //console.log(customers);
            resolve(customers);
        }catch(err){
            console.error('Error getting All Customers.')
            reject(err);
        }
        
    })
    
}
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
const GetAllCustomers=async(rows=[],pageSize=100,currentPage=0,search,filter,sortBy,order)=>{
    //console.log('rows start',rows);

    return new Promise(async (resolve,reject)=>{
        try{
           //console.log(`received search result request ${search}`);

            const qParams = new URLSearchParams();
            qParams.append("perPage", pageSize);
            qParams.append("page", currentPage);
            qParams.append("t",Date.now());
            if(search){
                qParams.append("q",search);
                //console.log('including search parameter ', search)
                rows=[];
            }else{
                //console.log('no search parameter');
            }
            if(sortBy){
                qParams.append("field",sortBy);
            }
            if(order){
                qParams.append("order",order);
            }
            if(filter){
                qParams.append("filter",filter);
            }
            
                
                //console.log(`rows length start ${rows.length}`);
                rows = rows.filter(f=>f.customer_id.indexOf('dummy')!=0)
                //console.log(`rows after dummy filter`, rows);
                let retdata = await API.get(APINAME,'/' + (qParams)? `?${qParams.toString()}`:'');
                
                //console.log(retdata);
                //console.log(`rows length after api call ${rows.length}`);
                let users = retdata.data;
                let totalitems = retdata.total;
                rows = (Array.isArray(rows))?rows.concat(users):users;
                //console.log('rows after result concat', rows);
                
                let dummyitems=0;
                if(rows.length!=totalitems){
                    //for(let i=startIndex+pageSize;i<totalitems;i++){
                    for(let i=rows.length;i<totalitems;i++){
                        rows.push({customer_id:`dummy${i}`});
                    }
                }
                
                dummyitems =  rows.filter(f=>f.customer_id.indexOf('dummy')==0).length;
                //console.log(`contains ${dummyitems} dummy rows`);
                
               
                if(!sortBy || sortBy == 'date_created'){
                    //default sort
                    rows = rows.sort((a, b) => (a.customer_name && b.customer_name)?a.customer_name.toLowerCase() > b.customer_name.toLowerCase() ? 1 : -1:1);
                }else{
                    //field sort
                    rows = rows.sort((a, b) => a[sortBy].toLowerCase() > b[sortBy].toLowerCase() ? 1 : -1);
                }

                if(rows.length>totalitems){
                    rows = rows.splice(0,totalitems);
                }

                //console.log('rows end',rows);
                resolve({
                      rows : rows
                    , totalItems : totalitems
                    , resolvedItems : totalitems-dummyitems
                });
           
            
        }catch(err){
            reject(err);
        }
        
    })
}
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
const GetCustomerLocations=async(customerId)=>{
    return new Promise(async (resolve,reject)=>{
        try{
            let customers = await API.get(APINAME,'/locations/'+customerId);
            resolve(customers);
        }catch(err){
            console.error('Error getting Customer Locations.')
            reject(err);
        }
        
    })
    
}
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
const GetAllRoles=async()=>{
    return new Promise(async (resolve,reject)=>{
        try{
            let customers = await API.get(APINAME,'/roles');
            customers = customers.sort();
            resolve(customers);
        }catch(err){
            console.error('Error getting All Roles.')
            reject(err);
        }
        
    })
}
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
const AddCustomer = async(customername,customernumber,poc)=>{

    let customerdata = {
        customer_name : customername
        , customer_number: customernumber
    }

   
    if(poc){
        customerdata.customer_poc_id=poc;
    }
    
    let req = {
            headers: {'Content-Type':'application/json'}
        , body: customerdata
    }
    
    return new Promise(async(resolve,reject)=>{
        try{
            let custinfo = await API.post(APINAME,'/',req);
            custinfo.poc = poc;
            custinfo.users = [];
            custinfo.locations=[];
            resolve(custinfo);
        }catch(err){
            console.error('Error Adding Customer.')
            reject(err);
        }
    })
}
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
const DeleteCustomer = async(customerid)=>{
    
    return new Promise(async(resolve,reject)=>{
        try{
            let delinfo = await API.del(APINAME,'/'+customerid);
            resolve(delinfo);
        }catch(err){
            console.error('Error deleting Customer.')
            reject(err);
        }
    })
}
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
const DisableCustomer = async(customerid)=>{
    return new Promise(async(resolve,reject)=>{
        let customerData = {
            is_active: 'N'
        }
        let req = {
              headers: {'Content-Type':'application/json'}
            , body: customerData
        }
        try{
            let custinfo = await API.put(APINAME,'/'+customerid, req);
            resolve(custinfo);
        }catch(err){
            console.error('Error disabling customer.')
            reject(err);
        }
    })
}
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
const DisableCustomers=async(customers)=>{
    let promises = []
    for(let customer of customers){
        promises.push(DisableCustomer(customer.id));
    }
    return await Promise.all(promises);
}

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
const EnableCustomer = async(customerid)=>{
    return new Promise(async(resolve,reject)=>{
        let customerData = {
            is_active: 'Y'
        }
        let req = {
              headers: {'Content-Type':'application/json'}
            , body: customerData
        }
        try{
            let custinfo = await API.put(APINAME,'/'+customerid, req);
            resolve(custinfo);
        }catch(err){
            console.error('Error disabling customer.')
            reject(err);
        }
    })
}
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
const EnableCustomers=async(customers)=>{
    let promises = []
    for(let customer of customers){
        promises.push(EnableCustomer(customer.id));
    }
    return await Promise.all(promises);
}
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
const UpdateCustomer = async(customerid, customerNumber, customerName, poc)=>{
    return new Promise(async(resolve,reject)=>{
        let customerData = {
            customer_name: customerName
            ,customer_number: customerNumber
            ,customer_poc_id: poc
        }
        
        let req = {
              headers: {'Content-Type':'application/json'}
            , body: customerData
        }
        try{
            let custinfo = await API.put(APINAME,'/'+customerid, req);
            resolve(custinfo);
        }catch(err){
            console.error('Error updating customer.')
            reject(err);
        }
    })
}
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
const SendContentAssignmentNotification = async(assignmentData)=>{
    return new Promise(async(resolve,reject)=>{
        
        let req = {
              headers: {'Content-Type':'application/json'}
            , body: assignmentData
        }
        try{
            let custinfo = await API.post(APINAME,'/notify', req);
            resolve(custinfo);
        }catch(err){
            console.error('Error updating customer.')
            reject(err);
        }
    })
}
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
const SendMessage = async(messageData)=>{
    return new Promise(async(resolve,reject)=>{
        
        let req = {
              headers: {'Content-Type':'application/json'}
            , body: messageData
        }
        try{
            let rsp = await API.post(APINAME,'/sendmessage', req);
            resolve(rsp);
        }catch(err){
            console.error('Error updating customer.')
            reject(err);
        }
    })
}
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
export { GetAllCustomers,GetCustomerLocations,GetAllRoles,DeleteCustomer, DisableCustomer, DisableCustomers, EnableCustomer
        , EnableCustomers, AddCustomer, UpdateCustomer,SendContentAssignmentNotification,SendMessage }
