//styling
import '../../App.css';
import "../../css/customer_portal.css";
import "../../css/normalize.css";
import IconSearch from "../../images/icon_search.png";


//react components
import React, { Component, useState, useEffect  } from "react";
import { useSearchParams } from "react-router-dom";

//third-party components
import LinearProgress from '@mui/material/LinearProgress';
import { DataGrid, GridPagination } from '@mui/x-data-grid';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import LinkIcon from '@mui/icons-material/Link';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';

//customcomponents
import CustomNoRowsOverlay from '../CustomNoRowsOverlay';

//libraries
import { GetMyLinks } from '../../libs/Links';


export default function MyLinks(props){

    const [isLoading, setIsLoading]=useState(true);
    const [links,setLinks]=useState(props.linksData);
    const [columns, setColumns] = useState();
    //const [userInfo,setUserInfo]=useState(props.userInfo);
    const [searchParams,setSearchParams ] = useSearchParams();
    const [selected, setSelected] = useState();
    const [link, setLink]=useState();
    const [currentPage, setCurrentPage]=useState((props.messageData)?props.messageData.page || 0:0);
    const [isTableLoading, setIsTableLoading]=useState();
    const [pageSize, setPageSize]=useState((props.messageData)?props.messageData.pageSize || 100:100);
    const [sortBy, setSortBy]=useState();
    const [searchTerm, setSearchTerm]=useState();
    

    useEffect(()=>{
        if(props.linksData){
            setLinks(props.linksData);
            setCurrentPage(0);
            setPageSize(100);
        }
    },[props.linksData])


    useEffect(() => {
        //setUserInfo(props.userInfo);
       
        setColumns([
            {
                field: "actions",
                headerName: "Visit",
                description: 'Visit Link',
                sortable: false,
                width: 80,
                disableClickEventBubbling: true,
                disableColumnMenu: true,
                renderCell: (params) => {
                    return (
                        <Tooltip title="Visit Link">
                            <IconButton href={params.row.link} target="_blank"><LinkIcon /></IconButton>
                        </Tooltip>
                    );
                 }
            },
            {     field: 'title'
                , headerName: 'Title'
                , width: 300
            }
           ,{ 
                 field: 'description'
               , headerName: 'Description'
               , width: 525
           }
         ]);
        
        
         
         if(props.isSelected){
            LoadData({pageSize:pageSize, page: currentPage},links,(data)=>{
                return new Promise((resolve,reject)=>{
                    if(data  && props.onData){
                        props.onData(data);
                    }
                    setIsLoading(false);
                    resolve();
                })
            });
        }


        setSelected(props.isSelected)
       
        
      }, [props.isSelected]);//[props.userInfo,props.selected]);

    const handlePagination = (params, evt, details)=>{
                
       LoadData(params, links,props.onData);

    }

    const CustomPaginator=()=>{
        return (
                (isTableLoading)?
                <></>
                :<GridPagination disabled={!isTableLoading}/>
        );
    }


    const LoadData = (params,origdata,callback)=>{

            if(!origdata){
                origdata = {
                    rows:[]
                    ,totalItems:0
                    ,resolvedItems:0
                    ,page:0
                }
            }
            
            setIsTableLoading(true);
            if(!origdata || origdata.totalItems==0 || params.search || (origdata.resolvedItems<(params.pageSize*(params.page+1)) && origdata.resolvedItems!=origdata.totalItems)){
                setPageSize(params.pageSize);
            
                GetMyLinks( searchParams.get('userId')||props.userId,origdata.rows||[],params.pageSize,params.page, params.search, sortBy).then((data)=>{
                        
                    if(data){
                        setLinks(data);
                        if(callback){
                            if(origdata && origdata.resolvedItems!=data.resolvedItems || data.totalItems==0){
                                data.page=params.page
                                data.pageSize=params.pageSize
                                //console.log('sending data to callback');

                                callback(data).then(()=>{
                                    //console.log('back from callback');
                                    setPageSize(params.pageSize);
                                    setCurrentPage(params.page);
                                    setIsTableLoading(false);
                                });
                            }
                            
                        }else{
                            //console.log('no callback configured')
                            setPageSize(params.pageSize);
                            setCurrentPage(params.page);
                            setIsTableLoading(false);
                        }
                    }
                    
                    
                    
                    
                    
                    
                        /*setLinks(linkdata);
                        setIsLoading(false);
                        if(props.onData){
                            props.onData(linkdata);
                        }
                        setIsTableLoading(false);*/
                 }).catch((err)=>{
                        setIsLoading(false);
                        setLinks([]);
                        if(props.onData){
                            props.onData();
                        }
                        console.error('error fetching links',err);
                        setIsTableLoading(false);
                });


            }else{
                //console.log('pulling data form cache');
                //origdata.page=params.page;
                //origdata.pageSize=params.pageSize;
                callback(origdata);
                setIsTableLoading(false);
                setPageSize(params.pageSize);
                setCurrentPage(params.page);
            }
            
    }

    const searchInput = (e)=>{
        
        if(e.keyCode==13){
            LoadData({pageSize:pageSize, page: 0, search:searchTerm},undefined,(data)=>{
                return new Promise((resolve,reject)=>{
                    if(data  && props.onData){
                        props.onData(data);
                    }
                    setIsLoading(false);
                    resolve();
                })
            });
        }
    }
    

    return(

        (selected)?
        (isLoading)?
        <div id="my_links" className="my_links">
            <div className="row row_padding">
            <LinearProgress />
            </div>
        </div>
        :
        <div id="my_messages" className="my_messages">
        <div className="row row_padding">
        <p>Links simplify and centralize your access to Abbott provided systems.</p>
            <div className="container_search">
                    <OutlinedInput id="txtsearch" 
                        onKeyDown={searchInput} 
                        variant='outlined'
                        value={searchTerm}
                        placeholder='Search'
                        onChange={(e)=>setSearchTerm(e.target.value)}
                        startAdornment={<InputAdornment position="end"><IconButton aria-label="search" edge="start" onClick={(e)=>searchInput({keyCode:13})}><SearchIcon /></IconButton></InputAdornment>}
                        sx={{verticalAlign:'top'}}
                    />
            </div>
            <div id="message_table_container">
                <div>
                <table style={{width:'100%'}}></table>
                    <DataGrid
                        rows={links?.rows||[]}
                        columns={columns}
                        initialState={{
                        pagination: {
                            paginationModel: {
                            pageSize: pageSize,
                            page:currentPage
                            },
                        },
                        }}
                        pageSizeOptions={[10,25,50,100]}
                        //onRowSelectionModelChange={(val)=>alert(val)}
                        getRowId={(row)=>{return row.hosted_link_id}}
                        fullWidth
                        id="link_table"
                        className="hover row-border" 
                        onPaginationModelChange={handlePagination}
                        slots={{ 
                                pagination : CustomPaginator,
                                noRowsOverlay: CustomNoRowsOverlay
                        }}
                        //onRowClick={(m)=>{setMessage(m.row);}}
                        disableRowSelectionOnClick={true}
                        loading={isTableLoading}
                        autoHeight={true}
                        getRowClassName={(p)=>p.indexRelativeToCurrentPage % 2 === 0 ? 'grid-row-even':'grid-row-odd'}
                    />

                </div>
            </div>
        </div>
        </div>
        :
        <></>
        
                    
                    
                    
                    


    )
}