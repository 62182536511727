//styling
import '../../../App.css';
import "../../../css/admin_portal.css";
import "../../../css/normalize.css";


//react components
import React, { useState, useEffect  } from "react";
import { useFormContext, FormProvider } from 'react-hook-form';



//third-party components
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Tooltip from '@mui/material/Tooltip';
import EditIcon from '@mui/icons-material/Edit';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import Autocomplete from '@mui/material/Autocomplete';
import { DataGrid, GridPagination } from '@mui/x-data-grid';
import { Alert, AlertTitle } from "@mui/material";
import LoadingButton from '@mui/lab/LoadingButton';
import Input from '@mui/material/Input';
import Box from '@mui/material/Box';
import AddIcon from '@mui/icons-material/Add';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';




//customcomponents
import AssignOption  from './AssignOption';
import IconSelector from '../../IconSelector';
import LocationForm from './LocationForm';
import PromptUser from '../../PromptUser';

//libraries
import { GetAllCustomers,GetCustomerLocations, GetAllRoles} from '../../../libs/Customers';
import { DeleteLocation} from '../../../libs/Locations';
import { Button, LinearProgress } from '@mui/material';
import { AddLink, UpdateLink } from '../../../libs/Links';
import { AddMessage, UpdateMessage } from '../../../libs/Messages';
import { AddReport, UpdateReport } from '../../../libs/Reports';
import { AddContent, UpdateContent } from '../../../libs/Content';
import {  AddPOC, UpdatePOC, GetAllPOC } from '../../../libs/POC';

export default function AddUpdateLocations(props){

    
    const [showDialogue,setShowDialogue]=useState(false);
    const [isLoading, setIsLoading]=useState(false);
    const [initial,setInitial]=useState();
    const [assignOptionDialogueVisible, setAssignOptionDialogeVisible]=useState(false);
    const [severity,setSeverity]=useState('info');
    const [icon, setIcon]=useState('info');
    const [feedbackMessage, setFeedbackMessage]=useState();
    const [feedbackTitle, setFeedbackTitle]=useState();
    const [resetForm,setResetForm]=useState();
    const [editMode,setEditMode]=useState();
    const [selectedRecord,setSelectedRecord]=useState();
    const [showForm,setShowForm]=useState(false);
    const [selectedrowids,setSelectedRowIds]=useState([]);
    const [selectedRows,setSelectedRows]=useState([]);
    const [deleteParams, setDeleteParams]=useState({});
    const [disableParams, setDisableParams]=useState({});
    const [enableParams, setEnableParams]=useState({});
    const [showDeletePrompt,setShowDeletePrompt]=useState(false);
    
    
    
    const [pocData,setPOCData]=useState();
    const [locations, setLocations]=useState();
    const [selectedLocation, setSelectedLocation]=useState();
    const [locationName,setLocationName]=useState();
    const [locationAddress1,setLocationAddress1]=useState();
    const [locationAddress2,setLocationAddress2]=useState();
    const [locationCity,setLocationCity]=useState();
    const [locationState,setLocationState]=useState();
    const [locationZip,setLocationZip]=useState();
    const [locationCountry,setLocationCountry]=useState();
    const [locationPOC, setLocationPOC]=useState();
    
   
    //const { getValues } = useFormContext();

    useEffect(()=>{
        resetFeedback();
    },[showDialogue])

    
    useEffect(()=>{
        
        if(selectedRecord){
            //console.log(selectedRecord);
            setLocations(selectedRecord.locations);
        }
        
    },[selectedRecord])

   

    useEffect(() => {

        setShowDialogue(props.showDialogue);
        resetFeedback();
        if(!initial){
            setInitial(true);
        }

        setEditMode(props.edit);
        if(props.edit && props.selectedRecord){
            setSelectedRecord(props.selectedRecord);
        }else{
            clearForm();
        }
        
        

        LoadPOC();

      }, [props.showDialogue]);


    const LoadPOC = async()=>{
        let poc = await GetAllPOC();
        //console.log('poc data',poc);
        setPOCData(poc);
    }
    
    
    const LocationObjectUpdated=async(locationObject)=>{
        
        let crrLocations = [...locations];
        crrLocations = crrLocations.slice(0,crrLocations.indexOf(selectedLocation)).concat([locationObject]).concat(crrLocations.slice(crrLocations.indexOf(selectedLocation)+1));
        setLocations(crrLocations);
        
    }
    const LocationObjectAdded=async(locationObject)=>{
        
        let crrLocations = [...locations];
        crrLocations.unshift(locationObject);
        setLocations(crrLocations);

    }

    
    const clearForm=()=>{
        setLocations();
        setLocationName();
        setLocationAddress1();
        setLocationAddress2();
        setLocationCity();
        setLocationState();
        setLocationZip();
        setLocationCountry();
        setLocationPOC();
    }

    const resetFeedback=()=>{
        setSeverity('info');
        setIcon('info');
        setFeedbackTitle();
        setFeedbackMessage();
    }
  

    const closeDialogue=(e)=>{


        clearForm();
        resetFeedback();
        setSelectedRecord();
        setShowDialogue(false)
        if(props.onCloseCallback){
            props.onCloseCallback(locations);
        }
    }
    
    const removeLocation = (locationId)=>{
        let crrlocationss = locations;
        setLocations(locations.filter((a)=>a.location_id!=locationId));
    }

    const removeLocations = async (locationids)=>{
        try{
            let crrlocations = locations;
            
            setIsLoading(true);
            setSeverity('info');
            setIcon('info');
            setFeedbackTitle('Deleting Location');
            
            
            for(let locationid of locationids){
                
                let locationname = crrlocations.filter((a)=>a.location_id==locationid)[0].location_name
                setFeedbackMessage(`Deleting ${locationname} from Customer.`);
                await DeleteLocation(selectedRecord.customer_id,locationid);
                crrlocations = crrlocations.filter((a)=>a.location_id!=locationid);
            }

            setLocations(crrlocations);

            setSeverity('success');
            setIcon('verified');
            setFeedbackTitle('Locations Deleted Successfully');
            setFeedbackMessage(`${locationids.length} locations deleted successfully.`);
            
        
        }catch(err){
            setSeverity('error');
            setIcon('error');
            setFeedbackTitle('Error Deleting Content');
            setFeedbackMessage((err.response && err.response.data)?err.response.data.error:JSON.stringify(err));
            //console.log('error deleting content',err);
        }

        setIsLoading(false);
    }

    return(
       <>
        <Dialog
            open={(showDialogue)}
            //onClose={()=>setMessage(undefined)}
            PaperProps={{ style: {
                minHeight: '600px',
                maxHeight: '90%',
                minWidth: '800px',
                maxWidth: '90%'
              }}}
        >
            <DialogTitle>
                Manage Locations
            </DialogTitle>
            <IconButton
                aria-label="close"
                onClick={()=>closeDialogue()}
                sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500],
                }}
            >
                <CloseIcon />
            </IconButton>
            <DialogContent>
                {(isLoading)?<LinearProgress />:<></>}
                <Alert severity={severity} icon={<IconSelector icon={icon} />} style={{visibility: (feedbackMessage) ? 'visible' : 'hidden' }}>
                    <AlertTitle>{feedbackTitle}</AlertTitle>
                    {feedbackMessage}
                </Alert>
                <Button style={{visibility: ((severity=='success'&&props.onMakeAssignments)) ? 'visible' : 'hidden' }} onClick={(e)=>props.onMakeAssignments()}>Manage assignments</Button>
                <div style={{padding:'10px'}}>
                <Stack direction="row">
                    <Tooltip title="Add Location">
                        <IconButton onClick={(e)=>{setShowForm(true)}} ><AddIcon /></IconButton>
                    </Tooltip>
                    <Tooltip title="Delete Locations">
                        <IconButton onClick={(e)=>{
                                    
                                    setDeleteParams(selectedrowids);
                                    setShowDeletePrompt(true);

                                }} disabled={(selectedrowids.length==0)}><DeleteIcon /></IconButton>
                    </Tooltip>
                </Stack>
                </div>
                <div style={{padding:'10px'}}>
                <Stack spacing={5}>
                        <DataGrid
                                rows={locations||[]}
                                columns={[ 
                                            {     
                                                field: 'location_name'
                                            , headerName: 'Name'
                                            , width: 175
                                            }
                                            ,{ 
                                                field: 'address'
                                                , headerName: 'Address1'
                                                , width: 400
                                                , renderCell: (params) => {
                                                    return (
                                                        
                                                        <Box fullWidth>
                                                            {params.row.address_line1},{params.row.address_line2}, {params.row.city}, {params.row.state} {params.row.zip}
                                                        </Box>
                                                    )
                                                }
                                            },
                                            {     
                                                field: 'poc'
                                                , headerName: 'APOC Contacts'
                                                , width: 175
                                                , renderCell: (params) => {

                                                    let displayPocs=[];
                                                    if(!params.row.customer_poc_id || (Array.isArray(params.row.customer_poc_id) && params.row.customer_poc_id.length==0)){
                                                        
                                                        //no override set; get poc from customer record
                                                        let customerPocs = selectedRecord.customer_poc_id;
                                                        if(Array.isArray(customerPocs)){
                                                            customerPocs.forEach((p)=>{
                                                                displayPocs=((pocData.rows.filter((pd)=>pd.poc_id==p).length==1)?displayPocs.concat(pocData.rows.filter((pd)=>pd.poc_id==p)[0].poc_fullname):displayPocs.concat([]));
                                                            })
                                                        }else{
                                                            displayPocs.push((pocData.rows.filter((p)=>p.poc_id==customerPocs).length>0)?pocData.rows.filter((p)=>p.poc_id==customerPocs)[0].poc_fullname:selectedRecord.poc[0].poc_fullname);
                                                        }
                                                    }else{
                                                        //override set; get poc from override
                                                        let ovPocs = params.row.customer_poc_id;
                                                        
                                                        if(Array.isArray(ovPocs)){
                                                            ovPocs.forEach(o=>displayPocs.push((pocData.rows.filter((p)=>p.poc_id==o).length>0)?pocData.rows.filter((p)=>p.poc_id==o)[0].poc_fullname:selectedRecord.poc[0].poc_fullname))
                                                        }else{
                                                            displayPocs.push((pocData.rows.filter((p)=>p.poc_id==ovPocs).length>0)?pocData.rows.filter((p)=>p.poc_id==ovPocs)[0].poc_fullname:selectedRecord.poc[0].poc_fullname);
                                                        }
                                                    }
                                                    

                                                    //if customerPocs is an array, process individual
                                                    //else get listed POCS.

                                                    /*return (
                                                           `${(params.row.customer_poc_id && pocData.rows.filter((p)=>p.poc_id==params.row.customer_poc_id).length>0)?pocData.rows.filter((p)=>p.poc_id==params.row.customer_poc_id)[0].poc_fullname:selectedRecord.poc[0].poc_fullname}`
                                                    )*/
                                                           
                                                    return (
                                                        <List>
                                                            {displayPocs.map((p) => (
                                                                <ListItemText>{p}</ListItemText>
                                                            ))}
                                                        </List>
                                                    )
                                                        
                                                    
                                                }
                                            },
                                            {
                                                field: "action_edit",
                                                headerName: "",
                                                sortable: false,
                                                description: 'Edit',
                                                width: 50,
                                                disableClickEventBubbling: true,
                                                disableColumnMenu: true,
                                                renderCell: (params) => {
                                                    return (
                                                        <Tooltip title="Edit">
                                                            <IconButton onClick={(e)=>{
                                                                setSelectedLocation(params.row);
                                                                setShowForm(true);
                                                            }}><EditIcon /></IconButton>
                                                        </Tooltip>
                                                    );
                                                 }
                                            }
                                        ]}
                                initialState={{}}
                                checkboxSelection
                                //onRowSelectionModelChange={(val)=>alert(val)}
                                getRowId={(row)=>{return row.location_id}}
                                fullWidth
                                id="locations_table"
                                className="hover row-border" 
                                //onRowClick={(m)=>{setMessage(m.row);}}
                                disableRowSelectionOnClick={true}
                                autoHeight={true}
                                onRowSelectionModelChange={(ids) => {
                                    setSelectedRowIds(ids);
                                    /*let rows = [];
                                    ids.map((id)=>{ rows = rows.concat(locations.filter((r)=>r.id==id))});
                                    setSelectedRows(rows);*/
                                }}
                                
                        />
                </Stack>
                </div>
            </DialogContent>
            <DialogActions>
                <Button onClick={()=>closeDialogue()}>Close</Button>                
            </DialogActions>
        </Dialog>
        <LocationForm showDialogue={(showForm)} onCloseCallback={(e)=>{setShowForm(false);setSelectedLocation();}} selectedRecord={selectedLocation} customerId={(selectedRecord)?selectedRecord.customer_id:undefined} edit={(selectedLocation)} onLocationAdded={LocationObjectAdded} onLocationUpdated={LocationObjectUpdated}/>

        <PromptUser showDialogue={showDeletePrompt}
                            Title="Delete Locations"
                            Prompt="Are you sure you would like to delete the selected locations?" 
                            Params={deleteParams}
                            onCallback={async (e)=>{
                                
                                setShowDeletePrompt(false);
                                if(e.response.toLowerCase()=='yes'){
                                    
                                        removeLocations(e.params);
                                    
                                }
                                
                            }}
                        />


        </>
        

    )
}