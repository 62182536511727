//styling
import '../../App.css';
import "../../css/admin_portal.css";
import "../../css/normalize.css";

//react components
import * as React from 'react';
import {useState, useEffect} from 'react';

import { BrowserRouter, Routes, Route, Router, Redirect, useSearchParams } from 'react-router-dom';


//third-party components
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';


//customcomponents
import MyLinks from './MyLinks';
import MyMessages from './MyMessages';
import MyPOC from './MyPOC';
import MyReports from './MyReports';
import { ReportViewer } from '../ReportViewer';
import { LinearProgress } from '@mui/material';


import { GetMyReports } from '../../libs/Reports';
import { GetMyMessages } from '../../libs/Messages';
import { GetMyLinks } from '../../libs/Links';
import { GetMyPOC } from '../../libs/POC';
import ChangePassword from '../Public/ChangePassword';




const DisplayContent = (props)=>{

    
    const [value, setValue] = useState('1');
    const [path, setPath]=useState();
    const [selectedPath, setSelectedPath] = useState();
    const [switchContent, setSwitchContent] = useState();
    
    const [reportData, setReportData]=useState();
    const [pocData,setPOCData]=useState();
    const [messageData, setMessageData]=useState();
    const [linksData, setLinksData]=useState();
    const [userInfo, setUserInfo]=useState();
    const [searchParams,setSearchParams ] = useSearchParams();
    
    const [loadOrder,setLoadOrder] = useState(['/links','/reports','/messages','/poc'])
    const [isLoading,setIsLoading] = useState(false);
    const [isLoaded,setIsLoaded] = useState(false);
    const [isTriggered,setIsTriggered] = useState(false);

    useEffect(() => {

        
        let uri_stem = window.location.toString().match(/^https:\/\/.+?(\/.[^\?]+)/) || '/admin/customers';
        
        switch(uri_stem[1]){
        
            case '/links':
                setValue('1');
                setPath('/links')
            break;
            case '/reports':
                setValue('2');
                setPath('/reports')
            break;
            case '/messages':
                setValue('3');
                setPath('/messages')
            break;
            case '/poc':
                setValue('4');
                setPath('/poc')
            break;
            default:
                setValue('1');
                setPath('/links')
                handleChange();
            break;
            
        }
    
      }, [props.selectedPath]);

      const handleChange = (event, newValue) => {
        let switchto;
        
        switch(newValue){
            case '1':
                switchto='/links';
            break;
            case '2':
                switchto='/reports';
            break;
            case '3':
                switchto='/messages';
            break;
            case '4':
                switchto='/poc';
            break;
            default:
                switchto='/links';
            break;
        }
        if(switchto){
            setPath(switchto);
            props.switchContent(switchto);
            setValue(newValue||1);
        }
        
    };
    

    return(
        <Box sx={{ width: '100%', typography: 'body1' }} className="admin_container">
            <TabContext value={value}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <TabList onChange={handleChange} aria-label="User functions">
                        <Tab label="My Links" value="1" />
                        <Tab label="My Documents" value="2" />
                        <Tab label="My Messages" value="3" />
                        <Tab label="My Contacts" value="4" />
                    </TabList>
                </Box>
                <TabPanel value="1">
                    <MyLinks userInfo={props.userInfo}
                        isSelected={(props.selectedPath=='/links')}
                        onData={(data)=>{
                                return new Promise((resolve,reject)=>{
                                    if(!linksData){setLinksData(data)}
                                    resolve();    
                                })
                            }
                        }
                        linksData={linksData}
                    />
                </TabPanel>
                <TabPanel value="2">
                    <MyReports 
                        isSelected={(props.selectedPath=='/reports')}
                        onData={(data)=>{
                            return new Promise((resolve,reject)=>{
                                setReportData(data)
                                resolve();
                            })  
                        }}
                        reportData={reportData} 
                    />
                </TabPanel>
                <TabPanel value="3">
                    <MyMessages 
                            isSelected={(props.selectedPath=='/messages')}
                            onData={(data)=>{
                                
                                    return new Promise((resolve,reject)=>{
                                        if(!messageData){setMessageData(data)}
                                        resolve();
                                    })    
                                }
                            }
                            messageData={messageData}
                    />
                </TabPanel>
                <TabPanel value="4">
                    <MyPOC 
                            isSelected={(props.selectedPath=='/poc')}
                            pocId={(props && props.userinfo)?props.userinfo.customer_poc_id:undefined }
                            onData={(data)=>{if(!pocData){setPOCData(data)}}}
                            pocData={pocData} 
                            
                    />
                </TabPanel>
            </TabContext>
        </Box> 
    )

}


function AppRouter(props){
    
        
        const [path, setPath]=useState(props.path);
        
        useEffect(() => {
            const uri_stem = window.location.toString().match(/^https:\/\/.+?(\/.[^\?]+)/);

            let crrpath = (uri_stem&&uri_stem[1])?uri_stem[1]:'/links';
            //console.log('path changed in router', crrpath);
            setPath(crrpath);
            //props.switchContent(crrpath)
    
          }, [props.path]);

          
        return (
            
            <BrowserRouter>
               <Routes>
                    {(props.fullWindow==true)?
                        <Route path="/reports/viewer" element={<ReportViewer />}  />
                    :
                    <>

                    <Route path="/" element={<DisplayContent Path={path} switchContent={props.switchContent} selectedPath={path}/>} />
                    <Route path="/links" element={<DisplayContent Path={path} switchContent={props.switchContent} selectedPath={path}/> } />
                    <Route path="/messages" element={<DisplayContent Path={path} switchContent={props.switchContent} selectedPath={path}/>} />
                    <Route path="/poc" element={<DisplayContent Path={path} switchContent={props.switchContent} selectedPath={path}/>} />
                    <Route path="/reports" element={<DisplayContent Path={path}  switchContent={props.switchContent} selectedPath={path}/>} />
                    </>
                    }
                </Routes>
            </BrowserRouter>
            
        )
}

export default AppRouter