//styling
import '../../../App.css';
import "../../../css/admin_portal.css";
import "../../../css/normalize.css";


//react components
import React, { useState, useEffect  } from "react";
import { useFormContext, FormProvider } from 'react-hook-form';



//third-party components
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Tooltip from '@mui/material/Tooltip';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import Autocomplete from '@mui/material/Autocomplete';
import { DataGrid, GridPagination } from '@mui/x-data-grid';
import { Alert, AlertTitle } from "@mui/material";
import LoadingButton from '@mui/lab/LoadingButton';
import Input from '@mui/material/Input';



//customcomponents
import AssignOption  from './AssignOption';
import IconSelector from '../../IconSelector';

//libraries
import { GetAllCustomers,GetCustomerLocations, GetAllRoles} from '../../../libs/Customers';
import { Button, LinearProgress } from '@mui/material';
import { AddLink, UpdateLink } from '../../../libs/Links';
import { AddMessage, UpdateMessage } from '../../../libs/Messages';
import { AddReport, UpdateReport } from '../../../libs/Reports';
import { AddContent, UpdateContent } from '../../../libs/Content';

export default function AddUpdateContent(props){

    
    const [showDialogue,setShowDialogue]=useState(false);
    const [isLoading, setIsLoading]=useState(false);
    const [initial,setInitial]=useState();
    const [contentType, setContentType]=useState('link');
    const [linkTitle, setLinkTitle]=useState('');
    const [linkDescription, setLinkDescription]=useState('');
    const [linkUrl, setLinkUrl]=useState('');
    const [assignOptionDialogueVisible, setAssignOptionDialogeVisible]=useState(false);
    const [severity,setSeverity]=useState('info');
    const [icon, setIcon]=useState('info');
    const [feedbackMessage, setFeedbackMessage]=useState();
    const [feedbackTitle, setFeedbackTitle]=useState();
    const [resetForm,setResetForm]=useState();
    const [messageTitle,setMessageTitle]=useState('');
    const [messageSubject, setMessageSubject]=useState('');
    const [messageBody, setMessageBody]=useState('');
    const [reportTitle, setReportTitle]=useState('');
    const [reportDescription, setReportDescription]=useState('');
    const [reportFile, setReportFile]=useState('');
    const [editMode,setEditMode]=useState();
    const [selectedRecord,setSelectedRecord]=useState();
    //const { getValues } = useFormContext();

    useEffect(()=>{
        resetFeedback();
    },[contentType])

    useEffect(()=>{
        
        if(selectedRecord){
            
            switch(selectedRecord.contentType){
                case "link":
                    setLinkTitle(selectedRecord.title);
                    setLinkDescription(selectedRecord.description);
                    setLinkUrl(selectedRecord.link);
                break;
                case "message":
                    setMessageTitle(selectedRecord.title);
                    setMessageSubject(selectedRecord.description);
                    setMessageBody(selectedRecord.message);
                break;
                case "report":
                    setReportTitle(selectedRecord.title);
                    setReportDescription(selectedRecord.description);
                    //console.log(selectedRecord);
                break;
            }
            setContentType(selectedRecord.contentType);
        }
        
    },[selectedRecord])

    useEffect(() => {

        setShowDialogue(props.showDialogue);
        resetFeedback();
        if(!initial){
            setInitial(true);
        }

        setEditMode(props.edit);
        if(props.edit){
            setSelectedRecord(props.selectedRecord);
        }else{
            clearForm();
            setContentType('link');
        }
        

      }, [props.showDialogue]);
    
    const UpdateContentObject=async(e)=>{
        let contentObj = selectedRecord;
        let success = false;
        setIsLoading(true);
        switch(contentType){
            case "link":
                try{
                //console.log(`update link with ${linkTitle} ${linkUrl} ${linkDescription}`)
                //await UpdateLink(selectedRecord.id, linkTitle, linkDescription, linkUrl);
                await UpdateContent({contentType:contentType
                                     , id: selectedRecord.id
                                     , linkTitle : linkTitle
                                     , linkDescription : linkDescription
                                     , linkUrl: linkUrl});
                contentObj.title = linkTitle;
                contentObj.description = linkDescription;
                contentObj.link = linkUrl;                                     
                //console.log('updated content object');
                success=true;
                }catch(err){
                    //console.log(err);
                    setFeedbackTitle('Error Updating Content');
                    setSeverity('error');
                    setIcon('error');
                    setFeedbackMessage(JSON.stringify(err.response.data.error));
                }
                
            break;
            case "message":
                try{
                    //console.log(`update message with ${messageTitle} ${messageSubject} ${messageBody}`)
                    //await UpdateMessage(selectedRecord.id,messageTitle, messageSubject, messageBody);
                    await UpdateContent({contentType: contentType
                                        , id: selectedRecord.id
                                        , messageTitle : messageTitle
                                        , messageSubject : messageSubject
                                        , messageBody: messageBody});
                    contentObj.title = messageTitle;
                    contentObj.description = messageSubject;
                    contentObj.message = messageBody;
                    success=true;
                }catch(err){
                    setFeedbackTitle('Error Updating Content');
                    setSeverity('error');
                    setIcon('error');
                    setFeedbackMessage(JSON.stringify(err.response.data.error));
                }
            break;
            case "report":
                try{
                    //console.log(`update report with ${reportTitle} ${reportDescription} ${reportFile}`);
                    //console.log(reportFile.type);
                    //await UpdateReport(selectedRecord.id, reportTitle, reportDescription, reportFile);
                    await UpdateContent({contentType: contentType
                                        , id: selectedRecord.id
                                        , reportTitle: reportTitle
                                        , reportDescription : reportDescription
                                        , reportFile: reportFile});
                    //console.log('updated content object');
                    contentObj.title = reportTitle;
                    contentObj.description = reportDescription;
                    success=true;
                    
                }catch(err){
                    setFeedbackTitle('Error Updating Content');
                    setSeverity('error');
                    setIcon('error');
                    setFeedbackMessage(JSON.stringify(err.response.data.error));
                }
                    

            break;
        }
        setIsLoading(false);
        
        if(success){
            setFeedbackTitle('Success');
            setSeverity('success');
            setIcon('verified');
            setFeedbackMessage('Content Updated Successfully');
            
            //setTimeout(()=>resetFeedback(),3000);
            if(props.onContentUpdated){
                props.onContentUpdated(contentObj)
            }


            //setAssignOptionDialogeVisible(true);
            //setResetForm(Math.random());
        }else{
            //console.log('failure occurred');
        }
    }
    const AddContentObject=async(e)=>{
        
        
        let contentObj = {};
        let success = false;
        setIsLoading(true);
        switch(contentType){
            case "link":
                try{
                //console.log(`add link with ${linkTitle} ${linkUrl} ${linkDescription}`)
                //contentObj = await AddLink(linkTitle, linkDescription, linkUrl);
                contentObj = await AddContent({ contentType: contentType
                                                , linkTitle : linkTitle
                                                , linkDescription: linkDescription
                                                , linkUrl:linkUrl});
                //console.log('added content object');
                //console.log(contentObj);
                success=true;
                }catch(err){
                    setFeedbackTitle('Error Adding Content');
                    setSeverity('error');
                    setIcon('error');
                    setFeedbackMessage(JSON.stringify(err.response.data.error));
                }
                
            break;
            case "message":
                try{
                    //console.log(`add message with ${messageTitle} ${messageSubject} ${messageBody}`)
                    //contentObj = await AddMessage(messageTitle, messageSubject, messageBody);
                    contentObj = await AddContent({contentType:contentType
                                                    , messageTitle: messageTitle
                                                    , messageSubject: messageSubject
                                                    , messageBody: messageBody});
                    //console.log('added content object');
                    //console.log(contentObj);
                    success=true;
                }catch(err){
                    setFeedbackTitle('Error Adding Content');
                    setSeverity('error');
                    setIcon('error');
                    setFeedbackMessage(JSON.stringify(err.response.data.error));
                }
            break;
            case "report":
                try{
                    //console.log(`add report with ${reportTitle} ${reportDescription} ${reportFile}`);
                    //console.log(reportFile.type);
                    //contentObj = await AddReport(reportTitle, reportDescription, reportFile);
                    contentObj = await AddContent({ contentType:contentType
                                                    , reportTitle : reportTitle
                                                    , reportDescription: reportDescription
                                                    , reportFile: reportFile});
                    //console.log('added content object');
                    //console.log(contentObj);
                    success=true;
                    
                }catch(err){
                    setFeedbackTitle('Error Adding Content');
                    setSeverity('error');
                    setIcon('error');

                    let errmsg = err?.response?.data?.error || 'unspecified error'
                    setFeedbackMessage(JSON.stringify(errmsg));
                }
                    

            break;
        }
        setIsLoading(false);


        if(success){
            setFeedbackTitle('Success');
            setSeverity('success');
            setIcon('verified');
            setFeedbackMessage('Content Added Successfully');
            
            //setTimeout(()=>resetFeedback(),3000);
            if(props.onContentAdded){
                props.onContentAdded(contentObj)
            }

            clearForm();
            //setAssignOptionDialogeVisible(true);
            setResetForm(Math.random());
        }else{
            console.log('failure occurred while adding content');
        }
        

    }

    const onAssignmentOptionResponse=(response)=>{
        setAssignOptionDialogeVisible(false);
        switch(response.toLowerCase()){
            case "yes":
                //console.log('show content assignments');
                if(props.onMakeAssignments){
                    props.onMakeAssignments()
                }else{
                    //console.log('onMakeAssignments callback handler not defined');
                }
            break;
            case "no":
                if(props.onCloseCallback){
                    props.onCloseCallback();
                }
                //console.log('no content assignments to be made');
            break;
        }
        clearForm();
    }

    const clearForm=()=>{
        resetLink();
        resetMessage();
        resetReport();
    }

    const resetLink=()=>{
        setLinkTitle('');
        setLinkDescription('');
        setLinkUrl('');
    }
    const resetMessage=()=>{
        setMessageTitle('');
        setMessageSubject('');
        setMessageBody('');
    }
    const resetReport=()=>{
        setReportTitle('');
        setReportDescription('');
        setReportFile('');
    }
    const resetFeedback=()=>{
        setSeverity('info');
        setIcon('info');
        setFeedbackTitle();
        setFeedbackMessage();
    }
  

    const closeDialogue=(e)=>{

        resetLink();
        resetFeedback();
        setSelectedRecord();
        setShowDialogue(false)
        if(props.onCloseCallback){
            props.onCloseCallback(e);
        }
    }
    

    return(
       <>
        <Dialog
            open={(showDialogue)}
            //onClose={()=>setMessage(undefined)}
            PaperProps={{ style: {
                minHeight: '600px',
                maxHeight: '90%',
                minWidth: '800px',
                maxWidth: '90%'
              }}}
        >
            <DialogTitle>
                {(editMode)?'Edit':'Add'} Content
            </DialogTitle>
            <IconButton
                aria-label="close"
                onClick={()=>closeDialogue()}
                sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500],
                }}
            >
                <CloseIcon />
            </IconButton>
            <DialogContent>
                {(isLoading)?<LinearProgress />:<></>}
                <Alert severity={severity} icon={<IconSelector icon={icon} />} style={{visibility: (feedbackMessage) ? 'visible' : 'hidden' }}>
                    <AlertTitle>{feedbackTitle}</AlertTitle>
                    {feedbackMessage}
                </Alert>
                <Button style={{visibility: ((severity=='success'&&props.onMakeAssignments)) ? 'visible' : 'hidden' }} onClick={(e)=>props.onMakeAssignments()}>Manage assignments</Button>
                <div style={{padding:'10px'}}>
                <FormControl>
                    <FormLabel id="content-type-radio-group-label">Content Type</FormLabel>
                    <RadioGroup
                        aria-labelledby="content-type-radio-group-label"
                        defaultValue="female"
                        name="content-type-radio-buttons-group"
                        row={true}
                        value={contentType}
                        onChange={(e)=>setContentType(e.target.value)}
                        
                    >
                        <FormControlLabel value="link" control={<Radio disabled={editMode}/>} label="Link" />
                        <FormControlLabel value="message" control={<Radio disabled={editMode}/>} label="Message" />
                        <FormControlLabel value="report" control={<Radio disabled={editMode}/>} label="Report" />
                    </RadioGroup>
                </FormControl>
                </div>
                {(contentType=='link')?
                <div style={{padding:'10px',display:(contentType=='link')?'display':'none'}}>
                    <Stack spacing={2}>
                    <FormControl fullWidth>
                        <FormLabel id="link-title-label" >Title</FormLabel>
                        <TextField id="link-title" 
                                    aria-labelledby="link-title-label" 
                                    variant="outlined" 
                                    required
                                    value={linkTitle}
                                    onChange={(e)=>setLinkTitle(e.target.value)}
                                    />
                    </FormControl>
                    <FormControl fullWidth>
                        <FormLabel id="link-url-label" >URL</FormLabel>
                        <TextField id="link-url" 
                                    aria-labelledby="link-url-label" 
                                    variant="outlined" 
                                    required
                                    value={linkUrl}
                                    onChange={(e)=>setLinkUrl(e.target.value)}
                                    />
                    </FormControl>
                    <FormControl fullWidth>
                        <FormLabel id="link-description-label" >Description</FormLabel>
                        <TextField id="link-description" 
                                    aria-labelledby="link-description-label" 
                                    variant="outlined" 
                                    required
                                    value={linkDescription}
                                    onChange={(e)=>setLinkDescription(e.target.value)}
                                    />
                    </FormControl>
                    </Stack>
                </div>
                :<></>
                }
                {(contentType=='message')?
                <div style={{padding:'10px',display:(contentType=='message')?'display':'none'}}>
                    <Stack spacing={2}>
                    <FormControl fullWidth>
                        <FormLabel id="message-title-label" >Title</FormLabel>
                        <TextField id="message-title" 
                                    aria-labelledby="link-title-label" 
                                    variant="outlined" 
                                    required
                                    value={messageTitle}
                                    onChange={(e)=>setMessageTitle(e.target.value)}
                                    />
                    </FormControl>
                    <FormControl fullWidth>
                        <FormLabel id="message-subject-label" >Subject</FormLabel>
                        <TextField id="message-subject" 
                                    aria-labelledby="message-subject-label" 
                                    variant="outlined" 
                                    required
                                    value={messageSubject}
                                    onChange={(e)=>setMessageSubject(e.target.value)}
                                    />
                    </FormControl>
                    <FormControl fullWidth>
                        <FormLabel id="message-body-label" >Message Body</FormLabel>
                        <TextField id="message-body" 
                                    aria-labelledby="link-description-label" 
                                    variant="outlined" 
                                    required
                                    value={messageBody}
                                    onChange={(e)=>setMessageBody(e.target.value)}
                                    multiline
                                    minRows={10}
                                    />
                    </FormControl>
                    </Stack>
                </div>
                :<></>
                }
                {(contentType=='report')?
                <div style={{padding:'10px',display:(contentType=='report')?'display':'none'}}>
                    
                    <Stack spacing={2}>
                    <FormControl fullWidth>
                        <FormLabel id="report-title-label" >Title</FormLabel>
                        <TextField id="report-title" 
                                    aria-labelledby="report-title-label" 
                                    variant="outlined" 
                                    required
                                    value={reportTitle}
                                    onChange={(e)=>setReportTitle(e.target.value)}
                                    />
                    </FormControl>
                    <FormControl fullWidth>
                        <FormLabel id="report-description-label" >Description</FormLabel>
                        <TextField id="report-description" 
                                    aria-labelledby="report-description-label" 
                                    variant="outlined" 
                                    required
                                    value={reportDescription}
                                    onChange={(e)=>setReportDescription(e.target.value)}
                                    />
                    </FormControl>
                    {(!editMode)?
                    <FormControl fullWidth>
                        <FormLabel id="report-file-label" >File</FormLabel>
                        <Input id="report-file" 
                                type="file"
                                aria-labelledby="report-file-label" 
                                variant="outlined" 
                                required
                                onChange={(e)=>setReportFile(e.target.files[0])}
                                />
                    </FormControl>
                    :<></>}
                    </Stack>
                    
                </div>
                :<></>
                }
                
            </DialogContent>
            <DialogActions>
                <LoadingButton onClick={()=>(editMode)?UpdateContentObject():AddContentObject()} loading={isLoading}>{(editMode)?'Update':'Add'}</LoadingButton>
                <Button onClick={()=>closeDialogue()}>Cancel</Button>                
            </DialogActions>
        </Dialog>
        <AssignOption showDialogue={assignOptionDialogueVisible} onCloseCallback={onAssignmentOptionResponse} />
        </>
        

    )
}