import * as React from 'react';
import VerifiedIcon from '@mui/icons-material/Verified';
import InfoIcon from '@mui/icons-material/Info';
import ErrorIcon from '@mui/icons-material/Error';
import WarningIcon from '@mui/icons-material/Warning';



export default function IconSelector(props){
        switch(props.icon){
            case "info":
                return (<InfoIcon /> )
            break;
            case "verified":
                return (<VerifiedIcon />)
            break;
            case "error":
                return (<ErrorIcon />)
            break;
            case "warn":
                return (<WarningIcon />)
            break;
        }
    
}



