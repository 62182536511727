//styling
import '../../../App.css';
import "../../../css/admin_portal.css";
import "../../../css/normalize.css";


//react components
import React, { useState, useEffect  } from "react";
import { useFormContext, FormProvider } from 'react-hook-form';



//third-party components
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Tooltip from '@mui/material/Tooltip';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import Autocomplete from '@mui/material/Autocomplete';
import { DataGrid, GridPagination } from '@mui/x-data-grid';
import { Alert, AlertTitle } from "@mui/material";
import LoadingButton from '@mui/lab/LoadingButton';
import Input from '@mui/material/Input';



//customcomponents
import AssignOption  from './AssignOption';
import IconSelector from '../../IconSelector';

//libraries
import { GetAllCustomers,GetCustomerLocations, GetAllRoles} from '../../../libs/Customers';
import { Button, LinearProgress } from '@mui/material';
import { AddLink, UpdateLink } from '../../../libs/Links';
import { AddMessage, UpdateMessage } from '../../../libs/Messages';
import { AddReport, UpdateReport } from '../../../libs/Reports';
import { AddContent, UpdateContent } from '../../../libs/Content';
import {  AddPOC, UpdatePOC } from '../../../libs/POC';

export default function AddUpdatePOC(props){

    
    const [showDialogue,setShowDialogue]=useState(false);
    const [isLoading, setIsLoading]=useState(false);
    const [initial,setInitial]=useState();
    const [linkTitle, setLinkTitle]=useState('');
    const [linkDescription, setLinkDescription]=useState('');
    const [linkUrl, setLinkUrl]=useState('');
    const [assignOptionDialogueVisible, setAssignOptionDialogeVisible]=useState(false);
    const [severity,setSeverity]=useState('info');
    const [icon, setIcon]=useState('info');
    const [feedbackMessage, setFeedbackMessage]=useState();
    const [feedbackTitle, setFeedbackTitle]=useState();
    const [resetForm,setResetForm]=useState();
    const [messageTitle,setMessageTitle]=useState('');
    const [messageSubject, setMessageSubject]=useState('');
    const [messageBody, setMessageBody]=useState('');
    const [reportTitle, setReportTitle]=useState('');
    const [reportDescription, setReportDescription]=useState('');
    const [reportFile, setReportFile]=useState('');
    const [editMode,setEditMode]=useState();
    const [selectedRecord,setSelectedRecord]=useState();

    const [pocFullName, setPOCFullName]=useState();
    const [pocTitle, setPOCTitle]=useState();
    const [pocEmail, setPOCEmail]=useState();
    const [pocDeskPhone, setPOCDeskPhone]=useState();
    const [pocCellPhone, setPOCCellPhone]=useState();
    const [isDefaultPOC, setIsDefaultPOC]=useState(false);
    const [pocImageB64, setPOCImageB64]=useState();
    const [pocImage, setPOCImage]=useState();
    
    //const { getValues } = useFormContext();

    useEffect(()=>{
        resetFeedback();
    },[showDialogue])

    useEffect(()=>{
        if(pocImage){
            const reader = new FileReader();
            reader.onloadend = function() {
                setPOCImageB64(reader.result);
            }
            reader.readAsDataURL(pocImage);
            
        }
    },[pocImage]);

    useEffect(()=>{
        
        if(selectedRecord){
            
          //console.log(selectedRecord);
            setPOCFullName(selectedRecord.poc_fullname);
            setPOCTitle(selectedRecord.poc_title);
            setPOCEmail(selectedRecord.poc_email);
            setPOCDeskPhone(selectedRecord.poc_phone);
            setPOCCellPhone(selectedRecord.poc_cell);
            setIsDefaultPOC(selectedRecord.poc_default);
            setPOCImageB64(selectedRecord.poc_image);
        }
        
    },[selectedRecord])

    useEffect(() => {

        setShowDialogue(props.showDialogue);
        resetFeedback();
        if(!initial){
            setInitial(true);
        }

        setEditMode(props.edit);
        if(props.edit){
            setSelectedRecord(props.selectedRecord);
        }else{
            clearForm();
        }
        

      }, [props.showDialogue]);
    
    const UpdatePOCObj=async(e)=>{
        let contentObj = selectedRecord;
        let success = false;
        setIsLoading(true);
        let pocObj;
        try{
            //console.log(`update poc with ${pocFullName} ${pocTitle}`);
            //await UpdateReport(selectedRecord.id, reportTitle, reportDescription, reportFile);
            /*await UpdateContent({contentType: contentType
                                , id: selectedRecord.id
                                , reportTitle: reportTitle
                                , reportDescription : reportDescription
                                , reportFile: reportFile});*/
            pocObj = await UpdatePOC(selectedRecord.poc_id, pocFullName,pocCellPhone,pocEmail,pocImageB64,pocDeskPhone,pocTitle,isDefaultPOC);
            //console.log('updated POC object');
            
            success=true;
            
        }catch(err){
            setFeedbackTitle('Error Updating POC');
            setSeverity('error');
            setIcon('error');
            console.error(err);
            setFeedbackMessage(JSON.stringify(err.response.data.error));
        }
                    

           
        setIsLoading(false);
        
        if(success){
            setFeedbackTitle('Success');
            setSeverity('success');
            setIcon('verified');
            setFeedbackMessage('POC Updated Successfully');
            
            //setTimeout(()=>resetFeedback(),3000);
            if(props.onPOCUpdated){
                props.onPOCUpdated(pocObj)
            }


            //setAssignOptionDialogeVisible(true);
            //setResetForm(Math.random());
        }else{
            //console.log('failure occurred');
        }
    }
    const AddPOCObj=async(e)=>{
        
        if(pocFullName && pocEmail && pocTitle && pocImageB64 && (pocDeskPhone || pocCellPhone)){

            let pocObj = {};
            let success = false;
            setIsLoading(true);
            try{
                //console.log(`add poc ${pocFullName}`)
                //AddPOC(fullname,cell,email,imageb64,phone,title, isDefault)
                pocObj = await AddPOC(pocFullName,pocCellPhone,pocEmail,pocImageB64,pocDeskPhone,pocTitle,isDefaultPOC);
                
                //console.log('added poc');
                //console.log(pocObj);
                success=true;
            }catch(err){
                setFeedbackTitle('Error Adding POC');
                setSeverity('error');
                setIcon('error');
                setFeedbackMessage(JSON.stringify(err.response.data.error));
            }
                
            setIsLoading(false);


            if(success){
                setFeedbackTitle('Success');
                setSeverity('success');
                setIcon('verified');
                setFeedbackMessage('POC Added Successfully');
                
                //setTimeout(()=>resetFeedback(),3000);
                if(props.onPOCAdded){
                    props.onPOCAdded(pocObj)
                }

                clearForm();
                //setAssignOptionDialogeVisible(true);
                //setResetForm(Math.random());
            }else{
                //console.log('failure occurred');
            }
        }else{
            setFeedbackTitle('Missing Required Fields');
            setSeverity('info');
            setIcon('info');
            setFeedbackMessage('Please populate required fields and retry.');
        }
        
        

    }

    
    const clearForm=()=>{
        setPOCFullName('');
        setPOCTitle('');
        setPOCEmail('');
        setPOCDeskPhone('');
        setPOCCellPhone('');
        setIsDefaultPOC(false);
        setPOCImage();
        setPOCImageB64();
    }

    const resetFeedback=()=>{
        setSeverity('info');
        setIcon('info');
        setFeedbackTitle();
        setFeedbackMessage();
    }
  

    const closeDialogue=(e)=>{

        clearForm();
        resetFeedback();
        setSelectedRecord();
        setShowDialogue(false)
        if(props.onCloseCallback){
            props.onCloseCallback(e);
        }
    }
    

    return(
       <>
        <Dialog
            open={(showDialogue)}
            //onClose={()=>setMessage(undefined)}
            PaperProps={{ style: {
                minHeight: '600px',
                maxHeight: '90%',
                minWidth: '800px',
                maxWidth: '90%'
              }}}
        >
            <DialogTitle>
                {(editMode)?'Edit':'Add'} POC
            </DialogTitle>
            <IconButton
                aria-label="close"
                onClick={()=>closeDialogue()}
                sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500],
                }}
            >
                <CloseIcon />
            </IconButton>
            <DialogContent>
                {(isLoading)?<LinearProgress />:<></>}
                <Alert severity={severity} icon={<IconSelector icon={icon} />} style={{visibility: (feedbackMessage) ? 'visible' : 'hidden' }}>
                    <AlertTitle>{feedbackTitle}</AlertTitle>
                    {feedbackMessage}
                </Alert>
                <Button style={{visibility: ((severity=='success'&&props.onMakeAssignments)) ? 'visible' : 'hidden' }} onClick={(e)=>props.onMakeAssignments()}>Manage assignments</Button>
                <div style={{padding:'10px'}}>
                
                </div>
                <div style={{padding:'10px'}}>
                <Stack direction="row" spacing={5}>
                    <Stack>
                        <FormControl fullWidth>
                            <FormLabel id="poc-image-label" for="img">Select Image 200px X 300px</FormLabel>
                            <input type="file" 
                                    name="uploadfile" 
                                    id="img" 
                                    style={{"display":"none"}}
                                    onChange={(e)=>setPOCImage(e.target.files[0])}/>
                            <img src={pocImageB64} style={{maxWidth:300,minWidth:300}}/>
                        </FormControl>
                    </Stack>
                    <Stack spacing={2}>
                    <FormControl fullWidth>
                        <FormLabel id="poc-fullname-label" >Full Name</FormLabel>
                        <TextField id="poc-fullname" 
                                    aria-labelledby="poc-fullname-label" 
                                    variant="outlined" 
                                    required
                                    value={pocFullName}
                                    onChange={(e)=>setPOCFullName(e.target.value)}
                                    />
                    </FormControl>
                    <FormControl fullWidth>
                        <FormLabel id="poc-role-label">Role</FormLabel>
                        <Select id="poc-role"
                                labelId="poc-role-label"
                                value={pocTitle}
                                onChange={(e)=>setPOCTitle(e.target.value)}
                                >
                                <MenuItem value="Contracts">Contracts</MenuItem>
                                <MenuItem value="Customer Support">Customer Support</MenuItem>
                                <MenuItem value="Marketing">Marketing</MenuItem>
                                <MenuItem value="Sales">Sales</MenuItem>
                        </Select>
                    </FormControl>
                    <FormControl fullWidth>
                        <FormLabel id="poc-email-label" >Email</FormLabel>
                        <TextField id="poc-email" 
                                    aria-labelledby="poc-email-label" 
                                    variant="outlined" 
                                    required
                                    value={pocEmail}
                                    onChange={(e)=>setPOCEmail(e.target.value)}
                                    />
                    </FormControl>
                    <FormControl fullWidth>
                        <FormLabel id="poc-deskphone-label" >Desk Phone</FormLabel>
                        <TextField id="poc-deskphone" 
                                    aria-labelledby="poc-deskphone-label" 
                                    variant="outlined" 
                                    required
                                    value={pocDeskPhone}
                                    onChange={(e)=>setPOCDeskPhone(e.target.value)}
                                    />
                    </FormControl>
                    <FormControl fullWidth>
                        <FormLabel id="poc-cellphone-label" >Cell Phone</FormLabel>
                        <TextField id="poc-cellphone" 
                                    aria-labelledby="poc-cellphone-label" 
                                    variant="outlined" 
                                    required
                                    value={pocCellPhone}
                                    onChange={(e)=>setPOCCellPhone(e.target.value)}
                                    />
                    </FormControl>
                    <FormControl>
                        <FormLabel id="default-poc-radio-group-label">Default POC</FormLabel>
                        <RadioGroup
                            aria-labelledby="default-poc-radio-group-label"
                            defaultValue={false}
                            name="content-type-radio-buttons-group"
                            row={true}
                            value={isDefaultPOC}
                            onChange={(e)=>setIsDefaultPOC(e.target.value)}
                            
                        >
                            <FormControlLabel value={true} control={<Radio  />} label="Yes" />
                            <FormControlLabel value={false} control={<Radio />} label="No" />
                        </RadioGroup>
                    </FormControl>
                        
                    </Stack>
                </Stack>
                </div>
            </DialogContent>
            <DialogActions>
                <LoadingButton onClick={()=>(editMode)?UpdatePOCObj():AddPOCObj()} loading={isLoading}>{(editMode)?'Update':'Add'}</LoadingButton>
                <Button onClick={()=>closeDialogue()}>Cancel</Button>                
            </DialogActions>
        </Dialog>
        
        </>
        

    )
}