//styling
import '../../App.css';
import "../../css/customer_portal.css";
import "../../css/normalize.css";
import IconSearch from "../../images/icon_search.png";

//react components
import React, { useState, useEffect  } from "react";
import { useSearchParams } from "react-router-dom";


//third-party components
import { DataGrid, GridPagination } from '@mui/x-data-grid';
import LinearProgress from '@mui/material/LinearProgress';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Tooltip from '@mui/material/Tooltip';
import MessageIcon from '@mui/icons-material/Message';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';




//customcomponents
import CustomNoRowsOverlay from '../CustomNoRowsOverlay';

//libraries
import { GetMyMessages } from '../../libs/Messages';


export default function MyMessages(props){

    const [isLoading, setIsLoading]=useState(true);
    const [isTableLoading, setIsTableLoading]=useState();
    const [columns, setColumns]=useState([]);
    const [messages,setMessages]=useState(props.messageData || {rows:[],totalItems:0,resolvedItems:0, page:0});
    const [message,setMessage]=useState();
    const [currentPage, setCurrentPage]=useState((props.messageData)?props.messageData.page || 0:0);
    const [pageSize, setPageSize]=useState((props.messageData)?props.messageData.pageSize || 100:100);
    const [sortBy, setSortBy]=useState();
    const [searchParams,setSearchParams ] = useSearchParams();
    const [selected, setSelected] = useState();
    const [searchTerm, setSearchTerm]=useState();

    useEffect(()=>{
        if(props.messageData){
            setMessages(props.messageData);
            setCurrentPage(0);
            setPageSize(100);
        }
    },[props.messageData])



    useEffect(() => {
        setColumns([
            {
                field: "actions",
                headerName: "View",
                description: 'View Message',
                sortable: false,
                width: 80,
                disableClickEventBubbling: true,
                disableColumnMenu: true,
                renderCell: (params) => {
                    return (
                        <Tooltip title="View Message">
                            <IconButton onClick={()=>{setMessage(params.row)}} description="View Message"><MessageIcon /></IconButton>
                        </Tooltip>
                    );
                 }
            },
            {     field: 'created_date'
                , headerName: 'Date/Time'
                , width: 175
                , valueGetter: (params) =>{
                    return (params.row.created_date)?`${ new Date(params.row.created_date).toLocaleDateString()} ${new Date(params.row.created_date).toLocaleTimeString()}`:'' ;
                }
            }
           ,{ 
                 field: 'subject_line'
               , headerName: 'Subject'
               , width: 225
           }
           ,{ 
              field: 'message'
            , headerName: 'Message'
            , width: 450
        }
         ]);

         if(props.isSelected){
            LoadData({pageSize:pageSize, page: currentPage},messages,(data)=>{
                return new Promise((resolve,reject)=>{
                    if(data  && props.onData){
                        props.onData(data);
                    }
                    setIsLoading(false);
                    resolve();
                })
            });
        }
    
        setSelected(props.isSelected)
       
        
      }, [props.isSelected]);


      const CustomPaginator=()=>{
        return (
                (isTableLoading)?
                <></>
                :<GridPagination disabled={!isTableLoading}/>
        );
    }

    const handlePagination = (params, evt, details)=>{
                
            LoadData(params, messages,props.onData);

     }

     const LoadData = (params,origdata,callback)=>{

            if(!origdata){
                origdata = {
                    rows:[]
                    ,totalItems:0
                    ,resolvedItems:0
                    ,page:0
                }
            }
            setIsTableLoading(true);
            if(!origdata || origdata.totalItems==0 || (origdata.resolvedItems<(params.pageSize*(params.page+1)) && origdata.resolvedItems!=origdata.totalItems)){
                setPageSize(params.pageSize);
            
                
                GetMyMessages( searchParams.get('userId')||props.userId, origdata.rows||[], params.pageSize, params.page, params.search, sortBy).then(async (data)=>{
                    if(data){
                        setMessages(data);
                        if(callback){
                            if(origdata && origdata.resolvedItems!=data.resolvedItems || data.totalItems==0){
                                data.page=params.page
                                data.pageSize=params.pageSize
                                //console.log('sending data to callback');

                                callback(data).then(()=>{
                                    //console.log('back from callback');
                                    setPageSize(params.pageSize);
                                    setCurrentPage(params.page);
                                    setIsTableLoading(false);
                                });
                            }
                            
                        }else{
                            //console.log('no callback configured')
                            setPageSize(params.pageSize);
                            setCurrentPage(params.page);
                            setIsTableLoading(false);
                        }
                    }
                
                }).catch((err)=>{
                    console.error(`error fetching date for page ${params.page}`,err);
                    callback({rows:[],page:params.page,pageSize:params.pageSize}).then(()=>{
                        setPageSize(params.pageSize);
                        setCurrentPage(params.page);
                        setIsTableLoading(false);
                    });
                });
            }else{
                //console.log('pulling data form cache');
                //origdata.page=params.page;
                //origdata.pageSize=params.pageSize;
                callback(origdata);
                setIsTableLoading(false);
                setPageSize(params.pageSize);
                setCurrentPage(params.page);
            }
            
     }

     const searchInput = (e)=>{
        
        if(e.keyCode==13){
            LoadData({pageSize:pageSize, page: 0, search:searchTerm},undefined,(data)=>{
                return new Promise((resolve,reject)=>{
                    if(data  && props.onData){
                        props.onData(data);
                    }
                    setIsLoading(false);
                    resolve();
                })
            });
        }
    }

    return(
        (selected)?
        (isLoading)?
        <div id="my_messages" className="my_messages">
            <div className="row row_padding">
            <LinearProgress />
            </div>
        </div>
        :
        <div id="my_messages" className="my_messages">
            <div className="row row_padding">
                <p>Messages are provided to help users keep abreast of certain conditions, for example 'software upgrades'. Messages can also alert users to scheduled downtime or facility-specific communications affecting multiple users.</p>
                
                <div className="container_search">
                    <OutlinedInput id="txtsearch" 
                        onKeyDown={searchInput} 
                        variant='outlined'
                        value={searchTerm}
                        placeholder='Search'
                        onChange={(e)=>setSearchTerm(e.target.value)}
                        startAdornment={<InputAdornment position="end"><IconButton aria-label="search" edge="start" onClick={(e)=>searchInput({keyCode:13})}><SearchIcon /></IconButton></InputAdornment>}
                        sx={{verticalAlign:'top'}}
                    />
            </div>
                <div id="message_table_container">
                    <div>
                    <table style={{width:'100%'}}></table>
                        {message && 
                                <>
                                    
                                    <Dialog
                                        open={(message)}
                                        //onClose={()=>setMessage(undefined)}
                                        PaperProps={{ style: {
                                            minHeight: '400px',
                                            maxHeight: '90%',
                                            minWidth: '600px',
                                            maxWidth: '90%'
                                          }}}
                                    >
                                        <DialogTitle>
                                        {message.subject_line}
                                        </DialogTitle>
                                        <IconButton
                                        aria-label="close"
                                        onClick={()=>setMessage(undefined)}
                                        sx={{
                                            position: 'absolute',
                                            right: 8,
                                            top: 8,
                                            color: (theme) => theme.palette.grey[500],
                                        }}
                                        >
                                        <CloseIcon />
                                        </IconButton>
                                        <DialogContent>
                                        <DialogContentText id="alert-dialog-description">
                                            {message.message}
                                        </DialogContentText>
                                        </DialogContent>
                                        <DialogActions>
                                        </DialogActions>
                                    </Dialog>

                                </>
                        }
                        <DataGrid
                            rows={messages?.rows||[]}
                            columns={columns}
                            initialState={{
                            pagination: {
                                paginationModel: {
                                pageSize: pageSize,
                                page:currentPage
                                },
                            },
                            }}
                            pageSizeOptions={[10,25,50,100]}
                            //onRowSelectionModelChange={(val)=>alert(val)}
                            getRowId={(row)=>{return row.message_id}}
                            fullWidth
                            id="message_table"
                            className="hover row-border" 
                            onPaginationModelChange={handlePagination}
                            slots={{ 
                                    pagination : CustomPaginator,
                                    noRowsOverlay: CustomNoRowsOverlay
                            }}
                            //onRowClick={(m)=>{setMessage(m.row);}}
                            disableRowSelectionOnClick={true}
                            loading={isTableLoading}
                            autoHeight={true}
                            getRowClassName={(p)=>p.indexRelativeToCurrentPage % 2 === 0 ? 'grid-row-even':'grid-row-odd'}
                        />

                    </div>
                </div>
            </div>
        </div>
        :<></>
        
    )
}