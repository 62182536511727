
import { Auth,API } from "aws-amplify";

const APINAME='location'
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
const AddLocation = async(customerId,name,address1,address2,city,state,zip,country)=>{

    
    return new Promise(async(resolve,reject)=>{
       
        let locationObject = {
            customer_id: customerId
          , location_name: name
          , address_line1: address1
          , address_line2: address2
          , city: city
          , state: state
          , zip: zip
          , country: country
      }
        
        let req = {
              headers: {'Content-Type':'application/json'}
            , body: locationObject
        }
        try{
            let locationinfo = await API.post(APINAME,'/locations/'+customerId, req);
            resolve(locationinfo);
        }catch(err){
            console.error('Error adding location.')
            reject(err);
        }
    })
}
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
const UpdateLocation = async(customerId,locationId,name,address1,address2,city,state,zip,country,customer_poc_id)=>{

    
    return new Promise(async(resolve,reject)=>{
       
        let locationObject = {
            location_name: name
          , address_line1: address1
          , address_line2: address2
          , city: city
          , state: state
          , zip: zip
          , country: country
      }

      if(customer_poc_id) {
        locationObject.customer_poc_id=customer_poc_id;
      }



        let req = {
              headers: {'Content-Type':'application/json'}
            , body: locationObject
        }
        try{
            let locationinfo = await API.put(APINAME,'/locations/'+customerId+'/'+locationId, req);
            resolve(locationinfo);
        }catch(err){
            console.error('Error updating location.')
            reject(err);
        }
    })
}
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
const DeleteLocation = async(customerId,locationId)=>{

    
    return new Promise(async(resolve,reject)=>{
        
        let req = {
              headers: {'Content-Type':'application/json'}
        }
        try{
            let locationdel= await API.del(APINAME,'/locations/'+customerId+'/'+locationId, req);
            resolve(locationdel);
        }catch(err){
            console.error('Error deleting location.',err)

            reject(err);
        }
    })
}
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
export { AddLocation, UpdateLocation, DeleteLocation }